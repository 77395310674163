export const environment = {
  api: 'https://api-sandbox.karaokesmart.co/v2',  
  apiExtraHeaders: { headers: { 'X-API-Version': '2.1' } },
  production: true,
  debug: true,
  defaultConfig: 'config_backup',
  webDomains: {
    default: 'kantowebtest.herokuapp.com',
    global: 'kantowebtest.herokuapp.com',
    ES: 'kantowebtest.herokuapp.com'
  },
  firebase: {
    apiKey: 'AIzaSyCm5yKV8Ca9AkUfQ177qglfOew80RETMzU',
    authDomain: 'green-gasket-592.firebaseapp.com',
    databaseURL: 'https://green-gasket-592.firebaseio.com',
    functionsURL: 'https://us-central1-green-gasket-592.cloudfunctions.net',
    projectId: 'green-gasket-592',
    storageBucket: 'green-gasket-592.appspot.com',
    messagingSenderId: '666515694770',
    appId: '1:666515694770:web:6eded1d990e06354f015ab',
    measurementId: 'G-QW1XDY22SC',
  },
  fbAppId: '225926810886925',
  mixpanel: {
    token: '2d8bb2eb4da7282f52da135564bf8726',
  },
  sms: {
    api_key: 'b9e1f7e4ca74666dbc6590fc5b3c4878',
  },
  vizio:{
    appKey: 'kanto_karaoke-d4c593b9f2a7'
  },
  spreedly: {
    user: 'JRQnvDqKcq0OvQ9rSlJl5K1C2mn',
    pass: 'KsI4FHhX05L5Afaxp8OaGMi7xD9SHiCEiCIDODkLMdRx1F6Kr7h0xXie2LaKbid4',
  },
  gateways: {
    conekta: {
      token: 'key_GPqBwuzrd1SmxHrkPBf4WUg',
    },
    ebanx: {
      integration_key: 'test_ik_w2QWwn6r1QFp0oW51Nq02Q',
      public_key: 'test_pk_ZFEku-ifp-yCPzc0-gGehg',
      url: 'https://sandbox.ebanxpay.com/ws/token',
    },
    payu: {
      accountID: {
        default: '512323',
        AR: '512322',
        CL: '512325',
        CO: '512321',
        MX: '512324',
        PA: '512326',
        PE: '512323',
        BR: '512327',
      },
      public_key: 'PKaC6H4cEDJD919n705L544kSU',
      url: 'https://sandbox.api.payulatam.com/payments-api/4.0/service',
    },
  },
  buildNumber: '202491053521',
  version: '15.11.0',
};
